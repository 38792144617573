function Line_sticker_panel({ business_data, sendSticker, lang }) {
  const [lineStickerList, setLineStickerList] = React.useState([]);
  const [recentLineStickerList, setRecentLineStickerList] = React.useState([]);
  const [selectGroupPackage, setSelectGroupPackage] = React.useState({});
  const [openRecentSticker, setOpenRecentSticker] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const selectGroup = (item) => {
    setSelectGroupPackage(item);
  };

  const sendMessage = (message) => {
    let recentList = _.cloneDeep(recentLineStickerList);

    const existingStickerIndex = recentList.findIndex(
      (item) => item.sticker_id === message.line_sticker_id
    );

    const newSticker = {
      package_id: message.line_package_id,
      sticker_id: message.line_sticker_id,
      type: message.sticker_type,
    };

    if (existingStickerIndex !== -1) {
      recentList.splice(existingStickerIndex, 1);
    } else if (recentList.length >= 10) {
      recentList.pop();
    }

    recentList.unshift(newSticker);

    setRecentLineStickerList(recentList);
    sendSticker(message);
  };

  React.useEffect(() => {
    setLoading(true);
    getLineStickerList();
  }, []);

  const getLineStickerList = () => {
    axios({
      method: "get",
      url:
        backend +
        "line-messaging/" +
        business_data.uid +
        "/" +
        business_data.profile_id +
        "/" +
        business_data.app_id +
        "/" +
        "line-sticker-list",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "X-VC-Access-Token": business_data.access,
        Authorization: "Bearer " + business_data.token,
      },
    }).then((res) => {
      if (res.data.result === 0) {
        setLoading(false);
        setLineStickerList(res.data.lineStickerList);
        setRecentLineStickerList(res.data.recentLineSticker);
        if (res.data.recentLineSticker.length > 0) {
          setOpenRecentSticker(true);
        } else {
          setSelectGroupPackage(res.data.lineStickerList[0]);
        }
      }
    });
  };

  const generateImageLink = (type, stickerId) => {
    const platform = type === "STATIC" ? "android" : "iPhone";
    return `https://stickershop.line-scdn.net/stickershop/v1/sticker/${stickerId}/${platform}/${
      type === "STATIC" ? "sticker.png" : "sticker_animation@2x.png"
    }`;
  };

  const renderSticker = (stickerId, type, packageId) => {
    let imageLink = generateImageLink(type, stickerId, packageId);
    return (
      <StickerImage
        key={stickerId}
        onClick={() =>
          sendMessage({
            sticker_type: type,
            line_package_id: packageId,
            line_sticker_id: stickerId,
            line_sticker_url: imageLink,
          })
        }
      >
        <img
          className="image sticker"
          src={imageLink}
          style={{ height: "120px" }}
          alt={`Sticker ${stickerId}`}
        />
      </StickerImage>
    );
  };

  return (
      <div>
        {
          loading ? (<div style={{ width: "100%",
          minHeight: "150px", display: 'flex', justifyContent:"center", alignItems: 'center' }}>
            <div>
        <i class="icon icon-circle-notch animate-spin"></i>{" "}
              <span>{lang.__loading}</span>
              </div>
        </div>) : (
          <div>
                <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 20,
                  width: "100%",
                  height: "80px",
                  overflowX: "auto",
                }}
              >
                <div
                  style={{
                    cursor: "pointer",
                    display: "inherit",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "20px",
                  }}
                >
                  <i
                    style={{
                      color: "#65A6FF",
                      fontSize: "30px",
                      backgroundColor: openRecentSticker ? "#E1E7F2" : "",
                      borderRadius: "25%",
                    }}
                    class="icon-clock"
                    onClick={() => {
                      setOpenRecentSticker(true);
                      selectGroup({});
                    }}
                  ></i>
                </div>
        
                {lineStickerList &&
                  lineStickerList.map((item, index) => {
                    let imageLink =
                      "https://stickershop.line-scdn.net/stickershop/v1/sticker/" +
                      (item.start_sticker_id +
                        (item.type === "STATIC"
                          ? "/android/sticker.png"
                          : "/iPhone/sticker_animation@2x.png"));
        
                    return (
                      <div
                        key={index}
                        style={{
                          display: "inherit",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          className="image sticker"
                          src={imageLink}
                          onClick={() => {
                            selectGroup(item);
                            setOpenRecentSticker(false);
                          }}
                          style={{
                            height: 42,
                            cursor: "pointer",
                            backgroundColor:
                              selectGroupPackage.package_id === item.package_id
                                ? "#E1E7F2"
                                : "",
                            borderRadius: "25%",
                          }}
                        />
                      </div>
                    );
                  })}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 20,
                  overflowX: "auto",
                  width: "100%",
                  maxHeight: "150px",
                  minHeight: "150px",
                  paddingLeft: "20px",
                }}
              >
                {openRecentSticker
                  ? recentLineStickerList.map((value) =>
                      renderSticker(value.sticker_id, value.type, value.package_id)
                    )
                  : Array.from(
                      {
                        length:
                          selectGroupPackage.end_sticker_id -
                          selectGroupPackage.start_sticker_id,
                      },
                      (_, index) => {
                        const stickerId = selectGroupPackage.start_sticker_id + index;
                        return renderSticker(
                          stickerId,
                          selectGroupPackage.type,
                          selectGroupPackage.package_id
                        );
                      }
                    )}
              </div>
              </div>
        )
        }
    </div>
  );
}

const StickerImage = window.styled.div`
cursor: pointer;
transition: transform .2s;
:hover{
  transform: scale(1.1);
}
`;
